
  import ScreenSize from '@/mixins/ScreenSize';
  import Events from '@/events/Events';
  export default {
    components: {
      RegisterAndApplyButton: () =>
        import('@/components/RegisterAndApplyButton'),
    },
    mixins: [ScreenSize],
    props: {
      job: {
        type: Object,
        required: true,
      },
      application: {
        type: Object,
        required: false,
        default: () => ({}),
      },
      applyNowText: {
        type: String,
        required: false,
        default: '',
      },
    },
    data() {
      return {};
    },
    methods: {
      clickApplicationWebhook() {
        this.$emitWithClientData(Events.JobApplicationClickedFromDetails, {
          jobPostingId: this.job.id,
          type: 'JobDetails',
        });
      },
    },
  };
