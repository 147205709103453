
  import ScreenSize from '@/mixins/ScreenSize';
  import JobAlertDialog from '@/components/JobAlertDialog';
  export default {
    components: {
      JobAlertDialog,
    },
    mixins: [ScreenSize],
    props: {
      title: {
        type: String,
        required: false,
        default: 'Lass dich über neue Jobs automatisch informieren!',
      },
      jobPosting: {
        type: Object,
        required: false,
        default: () => {},
      },
      searchRequest: {
        type: String,
        required: false,
        default: '',
      },
      customTitle: {
        type: String,
        required: false,
        default: '',
      },
      alertType: {
        type: String,
        required: false,
        default: 'listing',
      },
    },
  };
