
  import ApplyNow from '@/components/candidate/ApplyNow';
  import JobDetailsData from '@/components/JobDetailsData';
  import ShareIcons from '@/components/ShareIcons';
  import LazyHydrate from 'vue-lazy-hydration';
  import JobAlert from '@/components/JobAlert';
  import {removeCssClass, addCssClass} from '@/helper/CssClassHelper';
  import ScreenSize from '@/mixins/ScreenSize';

  export default {
    components: {
      LazyHydrate,
      ApplyNow,
      JobDetailsData,
      ShareIcons,
      TeaseredArticlesCard: () =>
        import('@/components/elements/TeaseredArticlesCard'),
      SendViaEmail: () => import('@/components/SendViaEmail'),
      SimilarOffers: () => import('@/components/SimilarOffers'),
      JobAlert,
    },
    mixins: [ScreenSize],
    props: {
      section: {
        type: Object,
        required: true,
        default: () => {},
      },
      jobPosting: {
        type: Object,
        required: true,
        default: () => {},
      },
    },
    computed: {
      hasApplication() {
        return Boolean(this.jobPosting.application);
      },
      hasFavorite() {
        return Boolean(this.jobPosting.favorite);
      },
    },
    mounted() {
      this.showHideElement();
      window.onscroll = () => {
        this.showHideElement();
      };
    },
    methods: {
      showHideElement() {
        const breakpoint = this.isDesktopOrTablet ? 400 : 800;
        if (document.documentElement.scrollTop > breakpoint) {
          if (this.$auth.loggedIn && this.isMobile) {
            addCssClass(
              'pb-sticky-apply-now',
              'pb-sticky-apply-now-content-mobile'
            );
          } else {
            addCssClass('pb-sticky-apply-now', 'pb-sticky-apply-now-content');
          }
        } else {
          removeCssClass('pb-sticky-apply-now', 'pb-sticky-apply-now-content');
          removeCssClass(
            'pb-sticky-apply-now',
            'pb-sticky-apply-now-content-mobile'
          );
        }
      },
    },
  };
