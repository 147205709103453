export function updateCssClass(id, newClass, oldClass) {
  if (document.getElementById(id)) {
    document.getElementById(id).style.display = 'none';
    if (document.getElementById(id).classList.contains(oldClass)) {
      document.getElementById(id).classList.remove(oldClass);
    }
    document.getElementById(id).classList.add(newClass);
    document.getElementById(id).style.display = 'block';
  }
}
export function removeCssClass(id, cssClass) {
  if (
    document.getElementById(id) &&
    document.getElementById(id).classList.contains(cssClass)
  ) {
    document.getElementById(id).classList.remove(cssClass);
  }
}
export function addCssClass(id, cssClass) {
  if (document.getElementById(id)) {
    document.getElementById(id).classList.add(cssClass);
  }
}
